//
// Google maps
//
const scomMap = {
  init($target) {
    const myLatlng = new google.maps.LatLng(50.0366354, 14.5785181);
    const mapCenter = new google.maps.LatLng(50.06777495, 14.5785181);
    // const mapCenter = new google.maps.LatLng(50.06777495, 14.4328252);
    const prefix = $target.getAttribute('data-prefix') || '';
    const iconURL = `${prefix}assets/img/marker.png`;

    const mapOptions = {
      center: mapCenter,
      zoom: 12,
      scrollwheel: false,
      draggable: true,
      disableDefaultUI: true,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      styles: [
        {
          featureType: 'administrative',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#444444',
            },
          ],
        }, {
          featureType: 'landscape',
          elementType: 'all',
          stylers: [
            {
              color: '#f2f2f2',
            },
          ],
        }, {
          featureType: 'poi',
          elementType: 'all',
          stylers: [
            {
              lightness: '-18',
            },
            {
              visibility: 'on',
            },
            {
              saturation: '-100',
            },
          ],
        }, {
          featureType: 'road',
          elementType: 'all',
          stylers: [
            {
              saturation: -100,
            }, { lightness: 45 },
          ],
        }, {
          featureType: 'road.highway',
          elementType: 'all',
          stylers: [
            {
              visibility: 'simplified',
            },
          ],
        }, {
          featureType: 'road.arterial',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        }, {
          featureType: 'transit',
          elementType: 'all',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        }, {
          featureType: 'water',
          elementType: 'all',
          stylers: [
            {
              color: '#4f595d',
            }, { visibility: 'on' },
          ],
        },
      ],
    };

    const map = new google.maps.Map($target, mapOptions);

    const icon = {
      url: iconURL,
      // This marker is 167 pixels wide by 197 pixels high.
      size: new google.maps.Size(167, 197),
      // The origin for this image is (0, 0).
      origin: new google.maps.Point(0, 0),
      // scaled size

      scaledSize: new google.maps.Size((167 / 1.5), (197 / 1.5)),
      // The anchor for this image is the base of the flagpole at (0, 32).
      // 54, 196
      anchor: new google.maps.Point((54 / 1.5), (196 / 1.5)),
    };

    const marker = new google.maps.Marker({
      position: myLatlng,
      map,
      icon,
    });

  },
};

export default scomMap;

// scomMap.init();
