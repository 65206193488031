// Jump.js
// A small, modern, dependency-free smooth scrolling library by callmecavs.
// http://callmecavs.com/jump.js/
import jump from 'jump.js';

export default class InPageNavigation {
  constructor(link, menu = null) {
    this.$link = link;
    this.$menu = menu;
    this.targetID = this.$link.getAttribute('href') || this.$link.getAttribute('data-href');

    if (this.targetID.charAt(0) === '#') {

      this.$link.addEventListener('click', (event) => {
        event.preventDefault();

        jump(this.targetID, {
          // duration: distance => Math.abs(distance) / 2,
          duration: 1000,
          offset: document.querySelector('#header').offsetHeight * -1,
          // callback: undefined,
          // easing: ez.easeInOutQuad,
          // a11y: false,
        });

        if (this.$menu && this.$menu.getAttribute('data-state') === 'open') {
          this.$menu.setAttribute('data-state', 'closed');
        }
      }, false);
    }
  }
}
