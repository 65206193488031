const tabs = {
  init() {
    const $tabsParent = document.querySelector('[data-tabs]');

    if ($tabsParent) {
      const $tabSelector = $tabsParent.querySelector('select');

      $tabSelector.addEventListener('change', () => {
        const selected = $tabSelector.value;

        $tabsParent.querySelector('.active').classList.remove('active');
        $tabsParent.querySelector(`[data-tab="${selected}"]`).classList.add('active');
      }, false);
    }

  },
};

export default tabs;
