import { _toggleState } from './utilities';
import LocalPageNavigation from './in-page-navigation';
import scomMap from './map';
import tabs from './tabs';

const domLoad = () => {
  // const windowWidth = window.outerWidth;
  const $header = document.querySelector('#header');
  const $menu = $header.querySelector('.main-menu');
  const $mobileMenuToggle = $header.querySelector('#mobile-menu-toggle');

  // Mobile menu
  $mobileMenuToggle.addEventListener('click', (event) => {
    event.preventDefault();
    _toggleState($menu, 'closed', 'open');
  }, false);

  // Expandables
  const $expandContentToggles = document.querySelectorAll('[data-expandable-toggle]');
  Array.from($expandContentToggles).forEach(($toggle) => {
    $toggle.addEventListener('click', (event) => {
      event.preventDefault();
      _toggleState($toggle.parentNode.parentNode.parentNode.parentNode, 'closed', 'open');
    }, false);
  });

  // Local page navigation
  const $localPageLinks = document.querySelectorAll('[data-nav-local]');
  if ($localPageLinks) {
    Array.from($localPageLinks).forEach(($link) => {
      new LocalPageNavigation($link, $menu);
    });
  }

  // Tabs
  tabs.init();

  // Google maps
  const $googleMaps = document.querySelectorAll('[data-googlemap]');
  if ($googleMaps) {
    Array.from($googleMaps).forEach(($map) => {
      scomMap.init($map);
    });
  }
};

export default domLoad;

